



















































































































































































































































































































































































































































































































































































































































































































































































/deep/.upload{
    height: 40px !important;
    .el-upload{
        height: 40px !important;
        border: none;
    }
}
// 上传文件列表样式
.fileList{
    margin-top: 40px;
    .file-item{
        display: flex;
        .el-button{
            margin-left: 10px;
        }
    }
}
// 培训任务选择框样式
.select-header {
    font-size: 14px;
    padding: 0 20px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #606266;
    height: 34px;
    line-height: 34px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}
// 错误信息样式
.errorInfo{
    display: flex;
    .success{
        color: #5C6BE8;
    }
    .error{
        margin-left: 20px;
        color: red;
    }
    .el-button{
        margin-left:20px
    }
}
